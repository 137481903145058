$primary: #b95300;
$secondary: #833AB4;
$thirtiary: #C13584;
$white: #FFF;

$text-color-title: #3F3D56;
$text-color: #3F3D56;
$text-color-secondary: #6B6B6B;

$bg-secondary: #F2F2F6;

$radius: 5px;

$small-box-padding: 1.5rem;
$small-content-padding: 2.5rem;
$large-content-padding: 3.5rem;

$md-gap: 2rem;

$box-shadow-4: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.1), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.1);

$font-title: 'Montserrat', sans-serif;
$font-body: 'Nunito Sans', sans-serif;
