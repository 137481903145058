@import "../variables.scss";

.frequently-asked-questions {
  padding: $large-content-padding;
  outline: none;

  &__content-title {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  &__title {
    font-family: $font-title;
    margin-bottom: 2rem;

    width: 575px;
    max-width: 90%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__card {
    width: 90%;
  }

  &__button-contact-us {
    margin: 4rem 0;
    display: flex;
    justify-content: center;
  }

  &__button-contact-us a {
    color: white;
    width: 25rem;
    background-color: #ec9656;
    border-radius: 0.5rem;
  }

  .btn {
    border: none;
  }

  .btn:hover {
    color: white;
    background-color: #DB6A31;
  }
}

@media (min-width: 581px) and (max-width: 1000px) {
  .frequently-asked-questions {
    padding: $large-content-padding;

    &__question {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.5rem 0.8rem;
      width: 100%;
      height: 4.4rem;
      margin-bottom: 0.5rem;
      border: 0.031rem solid #e4e4e4;
      border-radius: $radius;
    }
  }
}

@media (max-width: 580px) {
  .frequently-asked-questions {
    padding: $small-content-padding;

    &__question {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.5rem 0.8rem;
      width: 100%;
      height: 4.4rem;
      margin-bottom: 0.5rem;
      border: 0.031rem solid #e4e4e4;
      border-radius: $radius;
    }

    &__question p {
      margin-left: 0.5rem;
    }
  }
}
