@import "../variables.scss";

.evidence {
  padding: $large-content-padding;
  background-color: $bg-secondary;

  &__container {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  &__content-title {
    font-family: $font-title;
    text-align: center;
    font-size: 2.4rem;
    line-height: 3.4rem;
  }

  &__title {
    text-align: center;
    width: 500px;
    max-width: 90%;
  }

  &__info-paragraph {
    display: flex;
    justify-content: center;
    font-size: 1.21rem;
    text-align: center;
  }

  &__info-paragraph-paragraph {
    text-align: center;
  }

  &__content-results {
    margin: 4rem 0;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__persona-image {
    position: relative;
    height: 100%;
    left: 1rem;
    z-index: 1;
  }

  &__container-results {
    display: grid;
    grid-template-columns: 60% .8fr;
    column-gap: 1rem;
    margin-top: 4rem;
    width: 100%;
    height: auto
  }

  &__students-results-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 1.3rem;
    background-color: #ffffff;
    border-radius: $radius;
    height: min-content;
  }

  &__students-results-videos {
    display: grid;
    row-gap: 1rem;
    column-gap: 1rem;
    iframe {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
  }

  &__video {
    background-color: #ffffff;
    border-radius: 5px;
  }

  &__achievements {
    margin-top: 7rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
  }

  &__achievements-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &__achievements-number {
    margin: 0;
    font-family: $font-title;
    font-weight: 800;
    font-size: 2.5rem;
    color: #A7156D;
  }

  &__achievements-info {
    margin: 0;
    font-size: 1.2rem;
  }

  &__button-contact-us {
    margin: 4rem 0;
    display: flex;
    justify-content: center;
  }

  &__button-contact-us a {
    color: white;
    width: 25rem;
    background-color: #ec9656;
    border-radius: 0.5rem;
  }

  .btn {
    border: none;
  }

  .btn:hover {
    color: white;
    background-color: #db6a31;
  }
}

@media (max-width: 580px) {
  .evidence {
    padding: $small-content-padding;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    &__persona-image {
      display: none;
    }

    &__content-results {
      margin-top: 2rem;
    }

    &__container-results {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
    }

    &__students-results-slide {
      width: 100%;
      padding: 1.5rem;
    }

    &__user-coment h2 {
      font-size: 1rem;
    }

    &__user-coment p {
      margin: 0 0.8rem;
      font-size: 0.8rem;
    }

    &__students-results-videos {
      margin-top: 1rem;
      flex-direction: row;
      grid-column-gap: 1.5rem;
      width: 100%;
    }
    &__video {
      width: 90%;
    }

    &__user {
      width: 4rem;
      height: 4rem;
      top: -2rem;
      left: auto;
    }

    &__achievements {
      margin-top: 14rem;
      font-size: 1rems;
      align-items: baseline;
      grid-column-gap: 1.5rem;
    }

    &__achievements-number {
      font-size: 2rem;
    }
  }
}

/* MEDIA QUERIES */

@media (min-width: 992px) and (max-width: 1200px) {
}

@media (min-width: 768px) and (max-width: 992px) {
  .evidence {
    padding: $large-content-padding;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    &__container-results {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 1rem;
    }

    &__content-results {
      margin-top: 2rem;
    }

    &__persona-image {
      display: none;
    }

    &__students-results-slide {
      width: 90%;
      padding: 1.5rem;
    }

    &__students-results-videos {
      margin-top: 1rem;
      flex-direction: row;
      justify-content: space-between;
      grid-column-gap: 1rem;
      width: 90%;
    }

    &__video {
      width: 90%;
    }

    &__user {
      width: 4rem;
      height: 4rem;
      top: -2.5rem;
      left: auto;
    }

    &__achievements {
      margin-top: 10rem;
      font-size: 1rem;
      align-items: baseline;
      grid-column-gap: 1.5rem;
    }

    &__achievements-data {
      margin-bottom: 2rem;
    }

    &__achievements-number {
      font-size: 2.5rem;
    }
  }
}

@media (min-width: 581px) and (max-width: 768px) {
  .evidence {
    padding: $large-content-padding;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    &__persona-image {
      display: none;
    }

    &__content-results {
      margin-top: 2rem;
    }

    &__container-results {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
    }

    &__students-results-slide {
      width: 100%;
      padding: 1.5rem;
    }

    &__user-coment h2 {
      font-size: 1rem;
    }

    &__user-coment p {
      font-size: 1rem;
    }

    &__students-results-videos {
      margin-top: 1rem;
      flex-direction: row;
      grid-column-gap: 1.5rem;
      width: 100%;
    }
    &__video {
      width: 90%;
    }

    &__user {
      width: 4rem;
      height: 4rem;
      top: -2rem;
      left: auto;
    }

    &__achievements {
      margin-top: 7rem;
      font-size: 1rem;
      align-items: baseline;
      grid-column-gap: 1.5rem;
    }

    &__achievements-number {
      font-size: 2rem;
    }

    &__achievements-data {
      margin-bottom: 2rem;
    }
  }
}

@media (max-width: 580px) {
  .evidence {
    padding: $small-content-padding;

    &__achievements {
      display: none;
      flex-direction: column;
      align-items: center;
      grid-column-gap: 1.5rem;
      margin-top: 22rem;
    }

    &__achievements-data {
      margin-bottom: 2rem;
    }

    &__user-coment h2 {
      font-size: 1rem;
    }

    &__user-coment p {
      font-size: 0.9rem;
    }
    
    &__students-results-slide {
      width: 100%;
      padding: 1.5rem;
    }
  }
}
