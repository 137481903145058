@import "../variables.scss";

.info {
  padding: $large-content-padding 0;

  &__content {
    display: flex;
    align-items: center;
    grid-column-gap: 12rem;
  }

  &__title {
    font-family: $font-title;
    font-size: 2rem;
    line-height: 3rem;
  }

  &__row {
    margin-bottom: 4rem;
    display: flex;
    align-items: center;
    column-gap: 10rem;
  }

  &__description {
    font-size: 1.25rem;
    line-height: 2.4rem;
    
  }

  &__topics {
    font-size: 1.25rem;
    line-height: 2.2rem;
  }

  &__professional-career a {
    color:  #ec9656!important;
  }

  &__topic {
    line-height: 2.4rem;
  }

  &__description-highlight {
    font-weight: 600;
  }

  &__image {
    height: 18rem;
  }

  &__image-profile {
    margin-bottom: 2rem;
    width: 18rem;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: $radius;
  }
}

@media (max-width: 768px) {
  .info {
    padding: $large-content-padding;

    &__content {
      margin: 1rem 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    // &__professional-career {
    //   order: 1;
    // }

    &__images-rhImage {
      margin-bottom: 1.5rem;
   }

    &__row {
      margin-bottom: 0;
      column-gap: 4rem;
    }
  }
}

