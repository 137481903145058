@import "../variables.scss";

.banner {
  width: 100%;
  height: calc(100vh - 100px);
  background-image: url("../assets/img/banner3.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &__description-content {
    width: 70%;
  }
  
  &__container {
    display: flex;
  }

  &__title {
    display: flex;
    flex-direction: column;
    line-height: 4rem;
    font-family: $font-title;
    font-size: 3rem;
    color: #3F3D56;
    margin-bottom: 1rem;
  }

  &__title-bold {
    color: #3F3D56;
    font-family: $font-title;
    font-weight: 700;
  }

  &__text-description {
    padding: 0.5rem 0;
    width: 76%;
    color: #3F3D56;
    font-family: $font-title;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.8rem;
    margin-bottom: 1.2rem;
  }

  .btn {
    margin-bottom: 0.8rem;
    background-color: #ec9656;
    border: none;
  }

  .btn:hover {
    background-color: #DB6A31;
  }
}

/* MEDIA QUERIES */

@media (max-width: 768px) {
  .banner {
    &__title{
      font-size: 2.5rem;
      line-height: 3rem;
      margin-top: 2.8rem;
      color: $primary;

      span {
        color: $primary;
      }
    }

    &__text-description {
      margin: 0;
      width: 100%;
      color: $text-color-secondary;
      line-height: 1.2rem;
    }
  }
}

@media (max-width: 576px) {
  .banner {
    align-items: flex-end;
    height: auto;
    background-image: url("../assets/img/svg/image-banner-small.svg");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
  
    &__container.container {
      padding: 0;
    }

    &__description {
      width: 100vw;
    }

    &__description-content {
      width: 100vw;
      height: auto;
      background: $bg-secondary;
      border: none;
      box-shadow: none;
      border-radius: 0;
      justify-content: center;
      align-items: center;
      padding-bottom: 0;
    }

    &__text-description {
      font-size: 1.4rem;
      line-height: 2.2rem;
      text-align: center;
      padding: 1rem 2rem;
    }
  
    &__container {
      display: flex;
      flex-direction: row-reverse;
    }
  
    &__title {
      text-align: center;
    }

    .btn {
      display: none;
    }
  }

}




