@import "../variables.scss";

.reliable-methodology {
  padding: $large-content-padding;

  &__container {
    text-align: center;
  }

  &__content-title {
    display: flex;
    justify-content: center;
  }

  &__title {
    font-family: $font-title;
    font-weight: 700;
    text-align: center;
    font-size: 2.4rem;
    line-height: 3.4rem;
  }

  &__info-paragraph {
    display: flex;
    justify-content: center;
    font-size: 1.25rem;
  }

  &__info-paragraph-paragraph {
    text-align: center;
  }

  &__content {
    display: flex;
    justify-content: center;
  }

  &__supporting-companies {
    width: 70%;
    height: 8rem;
    display: flex;
    align-items: center;
    margin-top: 2rem;
    border-radius: $radius;
    justify-content: space-evenly;
    box-shadow: $box-shadow-4;
  }

  
}

@media (min-width: 992px) and (max-width: 1200px) {
  .reliable-methodology {
    padding: $small-content-padding;
    text-align: center;

    &__supporting-companies {
      width: 60%;
    }
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .reliable-methodology {
    padding: $small-content-padding;
    text-align: center;

    &__supporting-companies {
      width: 80%;
    }
  }
}

@media (min-width: 581px) and (max-width: 768px) {
  .reliable-methodology {
    padding: $large-content-padding;
    text-align: center;

    &__supporting-companies {
      width: 100%;
    }

    &__companies img {
      width: 100px;
    }
  }
}

@media (max-width: 580px) {
  .reliable-methodology {
    padding: $small-content-padding;
    text-align: center;

    &__content {
      display: flex;
      justify-content: center;
    }

    &__supporting-companies {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      margin-top: 2rem;
    }

    &__companies {
      padding: 1rem;
    }

    &__companies img {
      width: 100px;
    }
  }
}
