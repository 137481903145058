@import '../variables.scss';

.header {
  .navbar {
    color: $text-color;
    padding: 1rem 0;
    align-items: center;
    
  }

  &__navbar-brand img {
    height: 2.5rem;
  }
  
  .show {
    margin-top: 1rem;
  }
}
