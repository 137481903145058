@import "../variables.scss";

.study-method {
  padding: $large-content-padding;
  
  &__content-title {

    font-family: $font-title;
    font-weight: 700;
    text-align: center;
    font-size: 2.4rem;
    line-height: 3.4rem;
  }

  &__info-paragraph {
    display: flex;
    justify-content: center;
  }

  &__info-paragraph-paragraph {
    text-align: center;
    font-size: 1.25rem;
  }

  &__content-cards {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: $md-gap;
    margin-top: $md-gap;
  }

  &__card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: $radius;
    box-shadow: $box-shadow-4;
    width: 100%;
    padding: $small-box-padding;
  }

  &__card img {
    width: 2.75rem;
    height: 2.75rem;
    margin-bottom: 0.4rem;
  }

  &__card-p {
    font-family: $font-body;
    margin: 1rem 0 0 0;
    text-align: center;
    font-weight: 600;
  }

  &__button-contact-us {
    margin: 4rem 0;
    display: flex;
    justify-content: center;
  }

  
  &__button-contact-us a {
    color: white;
    width: 25rem;
    background-color: #ec9656;
    border-radius: 0.5rem;
  }

  .btn {
    border: none;
  }

  .btn:hover {
    color: white;
    background-color: #DB6A31;
  }
}

/* MEDIA QUERIES */

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .study-method {
    padding: $large-content-padding;

    &__content-cards {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
    }

    &__card {
      margin-top: 1rem;
    }
  }
}


@media (min-width: 581px) and (max-width: 768px) {
  .study-method {
    padding: $large-content-padding;

    &__content-cards {
      display: grid;
      grid-template-columns: repeat(2, minmax(192px, 1fr));
      grid-template-rows: auto;
    }

    &__card {
      margin-top: 1rem;
    }
  }
}

@media (max-width: 580px) {
  .study-method {
    padding: $small-content-padding;

    &__content-cards {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: auto;
    }

    &__card {
      margin-top: 1rem;
    }
  }
}
