@import "../variables.scss";

.become-an-analyst {
  padding: $large-content-padding;
  background-color: $bg-secondary;

  &__container {
    text-align: center;
  }

  &__content-title {
    display: flex;
    justify-content: center;
  }

  &__title {
    font-family: $font-title;
    font-weight: 700;
    margin-bottom: 2rem;
    text-align: center;
    max-width: 90%;
    font-size: 2.4rem;
    line-height: 3.4rem;
  }

  &__content-personas {
    margin: 4rem 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 5rem;
  }

  &__persona {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__persona img {
    height: 10rem;
  }

  &__persona-title {
    font-size: 1.2rem;
    line-height: 2.2rem;
    font-weight: 600;
    text-align: center;
    margin-top: 2.5rem;
    font-weight: 900;
  }

  &__info {
    display: flex;
    justify-content: center;
  }

  &__info p {
    text-align: center;
    font-size: 1.25rem;
  }

  &__button-contact-us {
    margin: 4rem 0;
    display: flex;
    justify-content: center;
  }

  
  &__button-contact-us a {
    color: white;
    width: 25rem;
    background-color: #ec9656;
    border-radius: 0.5rem;
  }

  .btn {
    border:none;
  }

  .btn:hover {
    color: white;
    background-color: #DB6A31;
  }
}

@media (min-width: 581px) and (max-width: 1200px) {
  .become-an-analyst {
    padding: $large-content-padding;
    text-align: center;
    display: flex;
    justify-content: center;

    &__content-personas {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-row-gap: 4rem;
      grid-column-gap: 2rem;
    }

    &__persona-title {
      margin-top: 1rem;
    }
  }
}
@media (max-width: 580px) {
  .become-an-analyst {
    padding: $small-content-padding;
    text-align: center;

    &__content-personas {
      margin: 2rem 0;
      flex-direction: column;
    }

    &__persona {
      margin-top: 1.5rem;
    }

    &__persona img {
      height: 8rem;
    }

    &__title {
      margin: 0;
    }
  }
}
