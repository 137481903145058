@import "../variables.scss";

.subscribe-form {
  background-color: $bg-secondary;
  padding: $small-content-padding;

  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 3rem;
  }

  &__container {
    display: flex;
    gap: 1.2rem;
    flex-direction: column;
  }

  &__field {
    width: 50%;
    align-items: center;

    &--button {
      display: flex;
      justify-content: flex-end;
    }

    &--control {
      margin-top: 1rem;
    }
  }

  .btn-primary {
    background-color: #ec9656;
    border: none;
  }

  .btn-primary:hover {
    background-color: #DB6A31;
    transition: 0.5s;
  }

  &__input {
    padding: 12px 12px;
    border-radius: $radius;
  }

  &__lgpd-description {
    font-size: 0.875rem;
    color: $text-color-secondary;
  }
}

@media (min-width: 581px) and (max-width: 770px) {
  .subscribe-form {
    &__row {
      display: block;
    }

    &__container {
      gap: 0;
      max-width: 600px;
    }

    
    &__field {
      width: 100%;
      align-items: center;
      
      &--control {
        margin-bottom: 1rem;
      }
      
      &--button {
        justify-content: center;
      }
    }
    
    
    &__input {
      margin-bottom: 1rem;
    }
    
    
  }
}

@media (max-width: 580px) {
  .subscribe-form {
    &__row {
      display: block;
    }

    &__field {
      width: 100%;
      align-items: center;

      &--control {
        margin-bottom: 1rem;
      }

      &--button {
        justify-content: center;
      }
    }

    &__container {
      gap: 0;
    }

    &__input {
      margin-bottom: 1rem;
    }
  }
}

.animation-spin {
  animation: loading 2s linear infinite;
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  margin-right: 5px;
}