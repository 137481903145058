@import "./variables.scss";
@import "node_modules/bootstrap/scss/bootstrap";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $font-body;
  
}

.noshow {
  font-size: 0;
  margin: 0;
  padding: 0;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  color: #3f3d56;
}

.title {
  font-weight: 700;
  margin-bottom: 1rem;
  line-height: 2rem;
  color: $text-color-title;
}

.btn {
  letter-spacing: 1.05px;
}