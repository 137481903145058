@import "../variables.scss";

.footer {
  background-color: $bg-secondary;
  padding: $large-content-padding;

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__logo {
    font-size: 1.5rem;
    font-weight: 600;
    color: #f77737;
    text-decoration: none;
  }

  &__social-media {
    display: flex;
    justify-content: space-evenly;
    width: 12rem;
    height: 1.8rem;
  }

  &__contact {
    display: flex;
    flex-direction: column;
  }

  &__address {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
  }

  &__whatsapp-number {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
  }

  &__email {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
  }

  &__contact p {
    width: 18rem;
    font-size: 1rem;
    margin: 0 0 0 0.3rem;
    color: $text-color;
  }
}

@media (min-width: 681px) and (max-width: 768px) {
  .footer {
    &__content {
      justify-content: center;
      grid-column-gap: 1rem;
    }

    &__social-media {
      grid-column-gap: 1rem;
    }
  }
}

@media (max-width: 680px) {
  .footer {
    padding: $small-content-padding;

    &__content {
      flex-wrap: wrap;
      grid-row-gap: 1rem;
    }

    &__social-media {
      justify-content: space-between;
    }

    &__icon {
    }
  }
}
